/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name vidMedia
     *
     * @description video canvas
     *
     */
    angular.module('cyfi').directive('videoBuffering', [function () {
        return {
            restrict: 'E',
            require : '^videoModel',
            link : function ($scope, $element, $attrs, vidPlayerController) {
                //var spinner = angular.element('<div class="buffering-spinner"><svg viewBox="0 0 64 64"><g><circle cx="16" cy="32" stroke-width="0" r="3"><animate attributeName="fill-opacity" dur="750ms" values=".5;.6;.8;1;.8;.6;.5;.5" repeatCount="indefinite"></animate><animate attributeName="r" dur="750ms" values="3;3;4;5;6;5;4;3" repeatCount="indefinite"></animate></circle><circle cx="32" cy="32" stroke-width="0" r="3.1192"><animate attributeName="fill-opacity" dur="750ms" values=".5;.5;.6;.8;1;.8;.6;.5" repeatCount="indefinite"></animate><animate attributeName="r" dur="750ms" values="4;3;3;4;5;6;5;4" repeatCount="indefinite"></animate></circle><circle cx="48" cy="32" stroke-width="0" r="4.1192"><animate attributeName="fill-opacity" dur="750ms" values=".6;.5;.5;.6;.8;1;.8;.6" repeatCount="indefinite"></animate><animate attributeName="r" dur="750ms" values="5;4;3;3;4;5;6;5" repeatCount="indefinite"></animate></circle></g></svg></div>');
                var spinner = angular.element('<div class="buffering-spinner"><div></div><div></div><div></div></div>');

                $scope.$watch('isBuffering', function (isBuffering) {
                    if (isBuffering === true) {
                        $element.append(spinner);
                    } else if (isBuffering === false) {
                        $element.remove(spinner);
                    }
                });
            }

        };

    }]);
}());

/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('cyfi');

    /**
     * @ngdoc service
     * @name actorFactory
     *
     * @description Creates a new Actor Value object
     *
     * @return factory
     */
    module.service('actorFactory', function () {

        // actor value object
        var Actor = function (data) {
            this.type = 'actor';
            this.id = data.id;
            this.name = data.name;
            this.age = data.age;
            this.gender = data.gender; // female, male
            this.isFemale = (data.gender === 'female');
            this.quote = data.long;
            this.quoteShort = data.short;
            this.icons = [];
            this.tags = data.tags || [];
            this.profiles = data.profiles || [];

            this.clone = function () {
                return new Actor(data);
            };
        };

        return {
            createNewInstance : function (data) {
                return new Actor(data);
            }
        };
    });
}());

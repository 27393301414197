/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name videoHeader
     *
     * @description shows 1 video in the header: Player can choose between 1 of the 2 actors to play a scenario
     *
     */
    angular.module('cyfi').directive('videoHeader', ['videosResource', 'actorsResource','$state', 'profileService', 'Analytics', function (videosResource, actorsResource, $state, profileService, Analytics) {
        return {
            restrict: 'E',
            templateUrl : 'app/view/video-header.html',
            link: function($scope) {

                $scope.video = videosResource.getCurrentVideo();

                (function () {
                    var i, actors, actor;
                    $scope.actors = [];
                    actors = actorsResource.getActorsByIds($scope.video.actors);
                    for (var i = 0; i < actors.length; i++) {
                        actor = actors[i].clone();
                        actor.score = profileService.current.score.getScoreObject(actor.id, $scope.video.id);
                        $scope.actors.push(actor);
                    }
                }());

                $scope.startScenario = function (actorId) {
                    $state.go('app.scenario', {actorId:actorId, videoId:$scope.video.id});
                };

                Analytics.trackPageView('open-video-' + $scope.video.id);
            }
        };

    }]);
}());

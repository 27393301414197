/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name mobileNavigationControls
     */
    angular.module('cyfi').directive('mobileNavigationControls', ['$window', '$state', 'profileService', function ($window, $state, profileService) {
        return {
            restrict: 'E',
            remove: true,
            templateUrl: 'app/view/mobile-navigation-controls.html',
            link: function($scope,$element,$attr) {

                var didScroll = false;

                $scope.showHome = false;
                $scope.showMobileMenu = false;
                $scope.state = $state;

                function toggle(apply) {
                    if ($window.pageYOffset < 200 && $scope.showHome) {
                        $scope.showHome = false;
                        if (apply) {
                            $scope.$apply();
                        }
                    } else if ($window.pageYOffset > 200 && !$scope.showHome) {
                        $scope.showHome = true;
                        if (apply) {
                            $scope.$apply();
                        }
                    }
                }

                toggle();
                angular.element($window).bind("scroll", function() { toggle(true); });

                $scope.clearScore = function() {
                    if (window.confirm('Weet je zeker dat je je speelgeschiedenis wilt wissen?')) {

                        profileService.current.score.reset();
                        alert('Speelgeschiedenis gewist');

                    }
                };

                $scope.onHomeClick = function() {
                    if ($state.current.name == 'app.front-page') {
                        $scope.showMobileMenu = false;
                        document.body.scrollTop = 0;
                    } else {
                        $state.go('app.front-page');
                    }
                };

                $scope.$on('$stateChangeSuccess', function() {
                    $scope.showMobileMenu = false;
                });

                $scope.$watch('showMobileMenu', function(value) {
                    if (value) {
                        angular.element($window.document.body).addClass('noscroll');
                        document.body.scrollTop = 0;
                    } else {
                        angular.element($window.document.body).removeClass('noscroll');
                    }
                });

            }
        };
    }]);
}());

/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    angular.module('cyfi')
        .controller('appController', ['$scope', '$state', function ($scope, $state) {
            $scope.nav = {
                home : function () {
                    $state.go('app.front-page', {}, { inherit:false, reload: true });
                }
            };
        }]);
}()
);
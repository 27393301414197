/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name vidMedia
     *
     * @description video canvas
     *
     */
    angular.module('cyfi').directive('videoControls', [function () {
        return {
            restrict: 'E',
            templateUrl: 'app/view/scenario-player/video-controls.html',
            require : '^videoModel',
            link : function ($scope, $element, $attrs, vidPlayerController) {
                $scope.playPauseClickHandler = function () {
                    vidPlayerController.togglePlayPause();
                };
            }

        };

    }]);
}());

/*jslint browser: true*/
/*global angular startApp*/

(function () {
    'use strict';

    var app = angular.module('cyfi', [
        'templates',
        'translate',
        'ngAnimate',
        'ngCookies',
        'ui.router',
        'cyfi.routing',
        'cyfi.content',
        'cyfi.locale',
        'Analytics-module',
        'ngDialog',
        'utils',
        'array-utils',
        'adaptive.detection'
    ]);


    // -------------------------------
    // Language
    // -------------------------------
    app.config(['languageProvider', 'LOCALE_NL_NL', function (languageProvider, LOCALE_NL_NL) {
        languageProvider.setTranslation(LOCALE_NL_NL); // defined in locale_nl_NL.js
    }]);



    // -------------------------------
    // HTML templates for router
    // -------------------------------
    // do not remove module templates
    // in the compiled app we will use the module templates to add all html templates
    angular.module('templates', []);

    }()

);


// start point to create angular app
function startApp(config) {
    'use strict';

    function startAppImplementation() {
        // initialize app bases on config values
        var app = angular.module('cyfi');


        // remove debug info for performance boost
        // https://docs.angularjs.org/guide/production#disabling-debug-data
        if (!config.DEBUG_INFO) {
            app.config(['$compileProvider', function ($compileProvider) {
                $compileProvider.debugInfoEnabled(false);
            }]);
        }

        if (config.APP_VERSION) {
            app.constant('APP_VERSION', config.APP_VERSION);
        }

        if (config.CARROUSEL_INTERVAL) {
            app.constant('CARROUSEL_INTERVAL', config.CARROUSEL_INTERVAL);
        }

        // start application
        angular.bootstrap(document, ['cyfi'], {'strictDi' : true});
    }

    // wait until document ready
    angular.element(document).ready(function () {
            startAppImplementation();
    });

}
/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name vidMedia
     *
     * @description video canvas
     *
     */
    angular.module('cyfi').directive('videoCanvas', ['VIDEO_EVENTS', 'language', '$timeout', '$compile', function (VIDEO_EVENTS, language, $timeout, $compile) {
        return {
            restrict: 'E',
            template: '<div class="video-player-canvas" ng-click="video_clickedHandler()"><video width="100%" height="100%" webkit-playsinline playsinline></video></div>',
            require : '^videoModel',
            link : function ($scope, $element, $attrs, vidPlayerController) {

                var videoEl = $element[0].querySelector('video');
                var videoContainer = angular.element($element[0].querySelector('.video-player-canvas'));
                var $errorMessageEl;

                var vidPlayBtn = $compile('<div class="video-play-btn" ng-click="video_clickedHandler()"></div>')($scope);
                //var vidPauseBtn = angular.element('<div class="video-pause-btn"></div>)');

                $scope.video_clickedHandler = function () {
                    if (vidPlayerController.getEnabled()) {

                        if ($scope.isPlaying) {
                            videoContainer.prepend(vidPlayBtn);
                            vidPlayerController.togglePlayPause();
                            $timeout(function() {
                                var removeWatcherFn = $scope.$watch('isPlaying', function (newValue) {
                                    if (newValue) {
                                        removeWatcherFn();
                                        vidPlayBtn.remove();
                                        }
                                    });
                            });

                        } else {
                            vidPlayerController.togglePlayPause();
                        }

                    }
                };

                $scope.$on(VIDEO_EVENTS.PLAY_VIDEO, function () {
                    videoEl.play();
                });

                $scope.$on(VIDEO_EVENTS.PAUSE_VIDEO, function () {
                    videoEl.pause();
                });

                function onLoadMetadataAfterSourceChange (time) {

                    var onTimeUpdate = function () {
                        // show video when current time is the requested time
                        // some slow Windows IE systems shows video frames between start time
                        // and requested time
                        if (videoEl.currentTime > time) {
                            videoEl.removeEventListener('timeupdate', onTimeUpdate);
                            videoEl.style.visibility = 'visible';
                        }
                    }

                    var listener = function () {
                        //console.log('on metadata loaded');
                        videoEl.removeEventListener('loadedmetadata', listener);
                        //console.log('videoEl.currentTime', videoEl.currentTime);
                        //    console.log('timeA',time);
                        time = Number(time);
                        if (Math.round(videoEl.currentTime) != Math.round(time)) {
                            videoEl.addEventListener('timeupdate', onTimeUpdate)
                            videoEl.currentTime = time;
                        } else {
                            videoEl.style.visibility = 'visible';
                        }
                    };
                    return listener;

                }

                $scope.$on(VIDEO_EVENTS.CHANGE_SOURCE_VIDEO, function (event, source) {
                    //console.log("set source video", source);
                    var time = source.split("#t=");

                    if (time.length > 1) {
                        // hide video when new video is requested with media fragment
                        // some slow Windows IE systems shows video frames load video and requested time
                        videoEl.style.visibility = 'hidden';
                        videoEl.addEventListener('loadedmetadata', onLoadMetadataAfterSourceChange(time[1]));
                    }

                    videoEl.setAttribute('src', source);
                    videoEl.load();

                });

                function onLoadMetaData (event) {
                    vidPlayerController.updateTotalTime(event.target.duration);
                    vidPlayerController.updateCurrentTime(event.target.currentTime);
                    vidPlayerController.updateIsBuffering(false);
                }

                function onTimeUpdate (event) {
                    vidPlayerController.updateCurrentTime(event.target.currentTime);
                }

                function onError (event) {
                    if (!$errorMessageEl) {
                        $errorMessageEl = angular.element('<div class="video-error-message"><h2>' + language.getText('ERROR_VIDEO_TITLE') + '</h2><p>' + language.getText('ERROR_VIDEO_TEXT') + '</p></div>');
                    }

                    videoContainer.prepend($errorMessageEl);
                }

                function onWaiting (event) {
                    vidPlayerController.updateIsBuffering(true);
                }

                function onPlaying (event) {
                    vidPlayerController.updateIsBuffering(false);
                    vidPlayerController.updateIsPlaying(true);
                }

                function onPlay (event) {
                    vidPlayerController.updateIsPlaying(true);
                }

                function onPause (event) {
                    vidPlayerController.updateIsPlaying(false);
                }

                function onEnded (event) {
                    vidPlayerController.videoEnded();
                }

                videoEl.addEventListener('loadedmetadata', onLoadMetaData);
                videoEl.addEventListener('timeupdate', onTimeUpdate);
                videoEl.addEventListener('error', onError);
                videoEl.addEventListener('waiting', onWaiting);
                videoEl.addEventListener('playing', onPlaying);
                videoEl.addEventListener('play', onPlay);
                videoEl.addEventListener('pause', onPause);
                videoEl.addEventListener('ended', onEnded);


            }

        };

    }]);
}());

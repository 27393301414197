/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name contentFilters
     *
     * @description shows filter drop downs for filtering the content items
     *
     */
    angular.module('cyfi').directive('contentFilters', ['tagsResource', 'contentResource', '$rootScope','$state', 'actorsResource', 'videoToggleService', function (tagsResource, contentResource, $rootScope, $state, actorsResource, videoToggleService) {
        return {
            restrict: 'E',
            templateUrl : 'app/view/content-filters.html',
            link: function($scope, $element) {
                //var $filtersEl = angular.element($element[0].querySelector('.filter-bar-filters'));
                //var $moviesEl = angular.element($element[0].querySelector('.filter-bar-text.movie'));
                //var $actorsEl = angular.element($element[0].querySelector('.filter-bar-text.actor'));
                var $homeBtnEl = angular.element($element[0].querySelector('.home-btn'));

                $scope.toggleService = videoToggleService;
                $scope.showSubjectTags = true;
                $scope.actor = actorsResource.getCurrentActor();

                $scope.subjectTags = {
                    selected : null,
                    options : tagsResource.subjectTags
                };

                /*
                $scope.quoteTags  = {
                    selected : null,
                    options : tagsResource.quoteTags
                };
                */

                $scope.applyTagsFilter = function () {
                    var tags = [];
                    if ($scope.subjectTags.selected) {
                        tags.push($scope.subjectTags.selected.id);
                    }
                    /*
                    if ($scope.quoteTags.selected) {
                        tags.push($scope.quoteTags.selected.id);
                    }
                    */

                    contentResource.applyTagsFilter(tags);


                    if ($state.current.name !== 'app.front-page') {
                        $state.go('app.front-page', {keepfilter : true});
                    }
                };

                function hideShow (fromState) {

                    if (fromState && fromState.name == 'app.scenario' && window.isMobile) {
                        $scope.showSubjectTags = true;
                    }

                    switch ($state.current.name) {
                        case 'app.front-page':
                            //$filtersEl.css('display', 'block');
                            //$moviesEl.css('display', 'none');
                            //$actorsEl.css('display', 'none');
                            $homeBtnEl.css('display', 'none');
                            if (!$state.params.keepfilter) {
                                $scope.subjectTags.selected = null;
                            }
                        break;
                        case 'app.video':
                            $homeBtnEl.css('display', 'block');
                            //$moviesEl.css('display', 'block');
                            //$filtersEl.css('display', 'none');
                           // $actorsEl.css('display', 'none');
                        break;
                        case 'app.scenario':
                            $homeBtnEl.css('display', 'block');
                            if (window.isMobile) {
                                $scope.showSubjectTags = false;
                            }
                            //$moviesEl.css('display', 'block');
                            //$filtersEl.css('display', 'none');
                            //$actorsEl.css('display', 'none');
                        break;
                        case 'app.actor':
                            $homeBtnEl.css('display', 'block');
                            //$actorsEl.css('display', 'block');
                            //$moviesEl.css('display', 'none');
                            //$filtersEl.css('display', 'none');
                        break;
                        default:
                            $homeBtnEl.css('display', 'block');
                            //$filtersEl.css('display', 'none');
                           // $actorsEl.css('display', 'none');
                            //$moviesEl.css('display', 'none');
                    }
                }

                hideShow();

                $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                    hideShow(fromState);
                });
            }
        };
    }]);
}());

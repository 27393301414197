/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('cyfi');

    /**
     * @ngdoc service
     * @name videoFactory
     *
     * @description Creates a new Video Value object
     * A video can be played from multiple viewpoints (Actors). Each viewpoint is described as a scenario
     *
     * @return factory
     */
    module.service('videoFactory', function () {

        // scenario value object
        var Video = function (data) {
            this.type = 'video';
            this.id = data.id;
            this.name = data.title;
            this.actors = data.actors || [];
            this.videoCorrect = data.videoCorrect;
            this.videoWrong = data.videoWrong;
            this.tags = data.tags || [];
        };

        return {
            createNewInstance : function (data) {
                return new Video(data);
            }
        };
    });
}());

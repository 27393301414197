/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name actorHeader
     *
     * @description shows 1 actor in the header
     *
     */
    angular.module('cyfi').directive('actorHeader', ['actorsResource', 'Analytics', function (actorsResource, Analytics) {
        return {
            restrict: 'E',
            templateUrl : 'app/view/actor-header.html',
            link: function($scope) {
                $scope.actor = actorsResource.getCurrentActor();
                Analytics.trackPageView('open-actor-' + $scope.actor.id);
            }
        };
    }]);

    /**
     * @ngdoc directive
     * @name profile
     *
     * @description filter view woith rollover
     *
     */
    angular.module('cyfi').directive('profile', ['profileResource', '$document', function (profileResource, $document) {
        return {
            restrict: 'E',
            replace: true,
            template : '<li class="profile" ng-mouseover="showTooltip()" ng-mouseout="hideTooltip()"><div class="icon {{::id}}" ></div><p class="tooltip">{{::text}}</p></li>',
            link: function(scope, element, iAttr) {
                var profile = profileResource.getById(iAttr.profileId);
                var tooltip = element.find('p')[0];

                scope.id    = profile.id;
                scope.text  = profile.title;

                scope.showTooltip = function(){
                    angular.element(tooltip).css("display","block");
                    $document.on('touchstart', outsideClickHandler);

                };

                scope.hideTooltip = function(){
                    angular.element(tooltip).css("display","none");
                    $document.off('touchstart', outsideClickHandler);
                };

                // Click/Touch-handler for tablet
                var outsideClickHandler = function(event) {
                    if (!element[0].contains(event.target)) {
                        angular.element(tooltip).css("display","none");
                        $document.off('touchstart', outsideClickHandler);
                    }
                };

                scope.$on('$destroy', function() {
                    $document.off('touchstart', outsideClickHandler);
                });
            }
        };
    }]);
}());

(function () {
    'use strict';

    /**
     * @ngdoc constant
     * @name VIDEO_EVENTS
     *
     * @description controller for the scenario player
     *
     */
    angular.module('cyfi').constant('VIDEO_EVENTS', {
            PLAY_VIDEO : 'play_video',
            PAUSE_VIDEO : 'pause_video',
            CHANGE_SOURCE_VIDEO : 'change_source_video',
            VIDEO_ENDED : 'video_ended'
    });

}());


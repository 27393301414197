/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('cyfi');

    /**
     * @ngdoc service
     * @name profileService
     *
     * @description
     *
     * @return profileService
     */
    module.service('profileService', ['scoresFactory', 'ngDialog','$cookies', function (scoresFactory, ngDialog, $cookies) {

        var UserProfile = function (id, scoreObject) {
            this.id = id;
            this.score = scoreObject;
        };

        var service = {};
        service.NOT_SELECTED    = 'notSelected';
        service.MOBILE          = 'mobile';
        service.ANONYMOUS       = 'anonymous';
        service.COOKIE_NAME     = 'avatar';

        var _profiles = [];
        var _profileNotSelected = new UserProfile(service.NOT_SELECTED, scoresFactory.createNewInstance(service.NOT_SELECTED));
        var _profileMobile = new UserProfile('mobile', scoresFactory.createNewInstance(service.MOBILE, true));

        _profiles.push(new UserProfile('p1', scoresFactory.createNewInstance('p1', true)));
        _profiles.push(new UserProfile('p2', scoresFactory.createNewInstance('p2', true)));
        _profiles.push(new UserProfile('p3', scoresFactory.createNewInstance('p3', true)));
        _profiles.push(new UserProfile('p4', scoresFactory.createNewInstance('p4', true)));
        _profiles.push(new UserProfile(service.ANONYMOUS, scoresFactory.createNewInstance(service.ANONYMOUS)));

        if (!window.isMobile) {
            service.current = _profileNotSelected;
            service.profileAsked = false;
        } else {
            service.current = _profileMobile;
            service.profileAsked = true;
        }

        service.getList = function () {

            var list = [];

            for (var i = 0; i < _profiles.length; i++) {
                var free = _profiles[i].score.isFree();
                _profiles[i].free = free;

                if (_profiles[i].id === service.current.id) {
                    _profiles[i].current = true;
                }else{
                    //reset current
                    _profiles[i].current = false;
                }

                list.push(_profiles[i]);
            }

            return list
        };

        service.switchProfile = function (profileId) {
            for (var i = 0; i < _profiles.length; i++) {
                if (_profiles[i].id === profileId) {
                    service.current         = _profiles[i];
                    save(profileId);
                    break;
                }
            }
        };

        service.openChooseProfileDialog = function (allowClose, profileCheck) {
            ngDialog.open({
                template: '<user-profile-choose></user-profile-choose>',
                plain: true,
                showClose : allowClose,
                closeByDocument : allowClose,
                closeByEscape : allowClose,
                className: "user-profile-choose ngdialog-theme-default",
                data: {
                    'profileCheck': profileCheck
                }
            });
        };

        function save(id){
            $cookies.put(service.COOKIE_NAME, id);
        }

        if ($cookies.get(service.COOKIE_NAME)) {
            (function load () {
                service.switchProfile($cookies.get(service.COOKIE_NAME));
            }());
        }


        return service;
    }]);
}()
);

/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('utils', []);

    module.service('getAbsoluteUrl', function () {
        var a;

        return function(url) {
            if(!a) a = document.createElement('a');
            a.href = url;

            return a.href;
        };

    });


}());
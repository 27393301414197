/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name videoModel
     *
     * @description controller for the scenario player
     *
     */
    angular.module('cyfi').directive('videoModel', ['VIDEO_EVENTS', function (VIDEO_EVENTS) {
        return {
            restrict: 'A',
            controller: ['$scope', function($scope) {

                $scope.currentTime = 0;
                $scope.totalTime = 0;
                $scope.progress = 0; // 0 - 100%
                $scope.videoSrc;
                $scope.isBuffering;
                $scope.isPlaying;
                $scope.enabled = true;


                this.setEnabled = function (value) {
                    $scope.enabled = value;
                };

                this.getEnabled = function (value) {
                    return $scope.enabled;
                };

                this.updateIsBuffering = function (buffering) {
                    $scope.isBuffering = buffering;
                    $scope.$apply();
                };

                this.updateIsPlaying = function (isPlaying) {
                    $scope.isPlaying = isPlaying;
                    $scope.$apply();
                };

                this.updateTotalTime = function (time) {
                    $scope.totalTime = time;
                    $scope.$apply();
                };

                this.updateCurrentTime = function (time) {
                    $scope.currentTime = time;
                    $scope.progress = Math.min(100, ($scope.currentTime / $scope.totalTime) * 100);
                    $scope.$apply();
                };

                this.playVideo = function () {
                    $scope.$broadcast(VIDEO_EVENTS.PLAY_VIDEO);
                };

                this.pauseVideo = function () {
                    $scope.$broadcast(VIDEO_EVENTS.PAUSE_VIDEO);
                };

                this.togglePlayPause = function () {
                    if ($scope.isPlaying) {
                        this.pauseVideo();
                    } else {
                        this.playVideo();
                    }
                };

                this.setSource = function (src) {
                    $scope.videoSrc = src;
                    $scope.$broadcast(VIDEO_EVENTS.CHANGE_SOURCE_VIDEO, $scope.videoSrc);
                    this.updateIsBuffering(true);
                    //$scope.$apply();
                };

                this.videoEnded = function () {
                    this.updateIsPlaying(false);
                    $scope.$broadcast(VIDEO_EVENTS.VIDEO_ENDED);
                };

                this.getCurrentTime = function () {
                    return $scope.currentTime;
                };

                /**
                 * @param {string} name Event name to listen on.
                 * @return {function} listener Function to call when the event is emitted.
                 * @return {function} Returns a deregistration function for this listener.
                 */
                this.$on = function (name, listener) {
                    return $scope.$on(name, listener);
                };

            }]
        };

    }]);
}());

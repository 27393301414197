/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name footer
     *
     * @description the general footer
     *
     */
    angular.module('cyfi').directive('footer', [function () {
        return {
            restrict: 'E',
            templateUrl: 'app/view/footer-view.html'
        };

    }]);
}());
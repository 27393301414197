/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name videoHover
     *
     * @description on a hover: add and play a video
     *
     */
    angular.module('cyfi').directive('videoHover', ['$sce', '$detection', function ($sce, $detection) {
        return {
            restrict: 'E',
            remove: true,
            template: '<div class="video-container"><video loop ng-src="{{videoSource}}"></video></div>',
            link: function($scope,$element,$attr) {

                var videoContainer = $element.find('div')[0];
                var videoElement = angular.element(videoContainer).find('video')[0];

                function trustResource(resourceUrl) {
                    return $sce.trustAsResourceUrl(resourceUrl);
                }

                //No video-hover on mobile platforms
                if(!$detection.isiOS() && !$detection.isAndroid()){
                    $scope.videoSource = trustResource($attr.videoUrl);

                    $element.on('mouseenter', function() {
                        videoElement.currentTime = 0;
                        videoElement.play();
                        angular.element(videoContainer).css("display","block");
                    });

                    $element.on('mouseleave', function() {
                        videoElement.pause();
                        angular.element(videoContainer).css("display","none");
                    });
                }
            }
        };
    }]);
}());

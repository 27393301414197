/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name chooseActor
     *
     */
    angular.module('cyfi').directive('actorStars', [function () {
        return {
            restrict: 'E',
            //template: '<div class="stars-bg"><div class="stars-fill" ng-style="{ &quot;width&quot;: score + &quot;%&quot; }"></div></div>',
            template: '<div class="stars-bg"><div class="stars-fill"></div></div>',
            scope: {
                score: '='
            },
            link : function ($scope, $element) {
                var scoreEl = $element[0].querySelector('.stars-fill');
                scoreEl.style.width = 0;

                $scope.$watch('score', function (score) {
                    if (score) {
                        TweenMax.to(scoreEl, 1.2, {delay: 0.8, width:score+'%'});
                    } else {
                        scoreEl.style.width = 0;
                    }
                });
            }
        };

    }]);
}());
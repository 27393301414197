/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name chooseActor
     *
     */
    angular.module('cyfi').directive('chooseActor', [function () {
        return {
            restrict: 'E',
            templateUrl: 'app/view/ui/choose-actor.html',
            scope: {
                actors: '=',
                actorSelected : "&"
            },
            link : function ($scope, $element, $attrs, vidPlayerController) {
                $scope.startScenario = function (actorId) {
                    $scope.actorSelected({actorId : actorId});
                };
            }

        };

    }]);
}());
/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var app = angular.module('cyfi.routing', [
        'ui.router.router', 'Analytics-module']);

    /**
     * Configure states to views
     *
     * @see https://github.com/angular-ui/ui-router/wiki for information about the routing options
     */
    app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

        // For any unmatched url, redirect to front page
        $urlRouterProvider.otherwise('/app/front-page');

        // Now set up the states
        // Please read https://github.com/angular-ui/ui-router/wiki/Multiple-Named-Views
        $stateProvider
            .state('app', {
                url: '/app?istablet',
                abstract: true, // -> can not get activated itself.
                views : {
                    '' : {
                        templateUrl: 'app/view/app-view.html'
                    }
                }
            });

        // ------------ app ------------
        $stateProvider.state('mobile', {
            url: '/mobile',
            views : {
                '' : {
                    templateUrl: 'app/view/mobile.html'
                }
            }
        });

        $stateProvider.state('app.front-page', {
            url: '/front-page?keepfilter',
            views : {
                'content' : {
                    templateUrl: 'app/view/header-frontpage-view.html'
                }
            },
            resolve : {
                resetFilter : ['contentResource', '$stateParams', function (contentResource, $stateParams) {
                    if (!$stateParams.keepfilter) {
                        contentResource.applyTagsFilter();
                    }
                    return true;
                }]
            }
        });

        $stateProvider.state('app.video', {
            url: '/video?id',
            views : {
                'content' : {
                    templateUrl: 'app/view/header-video-view.html'
                }
            },
            resolve : {
                currentVideo : ['$stateParams', 'videosResource', 'contentResource', function ($stateParams, videosResource, contentResource) {
                    videosResource.setCurrentVideo($stateParams.id);
                    contentResource.applyVideoFilter($stateParams.id);
                    return videosResource.getCurrentVideo();
                }]
            }
        });

        $stateProvider.state('app.actor', {
            url: '/actor?id',
            views : {
                'content' : {
                    templateUrl: 'app/view/header-actor-view.html'
                }
            },
            resolve : {
                currentActor : ['$stateParams', 'actorsResource', 'contentResource', function ($stateParams, actorsResource, contentResource) {
                    actorsResource.setCurrentActor($stateParams.id);
                    contentResource.applyActorFilter($stateParams.id);
                    return actorsResource.getCurrentActor();
                }]
            }
        });

        $stateProvider.state('app.scenario', {
            url: '/scenario?actorId&videoId',
            views : {
                'content' : {
                    templateUrl: 'app/view/header-scenario-view.html',
                    controller: ['$scope', 'videoService', function($scope, videoService) {
                        $scope.videoService = videoService;
                    }]
                }
            },
            resolve : {
                currentScenario : ['$stateParams', 'actorsResource', 'videosResource', 'scenariosResource', function ($stateParams, actorsResource, videosResource, scenariosResource) {
                    actorsResource.setCurrentActor($stateParams.actorId);
                    videosResource.setCurrentVideo($stateParams.videoId);
                    scenariosResource.setCurrentScenario($stateParams.videoId, $stateParams.actorId);

                    return scenariosResource.getCurrentScenario();
                }]
            }
        });

    }]);

    // ------------------------------------------
    // first time a route is successfully changed after app is setup
    // ------------------------------------------
    app.run(['$rootScope', 'Analytics','APP_VERSION',
        function ($rootScope, Analytics, APP_VERSION) {

            var stateSuccess, stateError;
            function removeLoader () {
                // remove app loader
                var loaderIndicator = document.getElementById('appLoadingIndicator');
                loaderIndicator.parentNode.removeChild(loaderIndicator);
                stateSuccess(); // remove listeners
                stateError(); // remove listeners
            }

            stateSuccess = $rootScope.$on('$stateChangeSuccess', function () {
                //Analytics.setTrackingParam('appVersion', APP_VERSION);
                Analytics.trackPageView('open-can-you-fix-it');
                removeLoader();
            });

            stateError = $rootScope.$on('$stateChangeError', function () {
                removeLoader();
            });
        }]);

    app.run(['$rootScope', '$window',
        function($rootScope, $window) {
            $rootScope.$on('$stateChangeStart', function(){
                //$window.scrollTo(0,0);    //scroll to top of page after each route change

                if (!window.isMobile) {
                    TweenMax.to($window, 0.8, {scrollTo:{y:0}, ease:Power2.easeOut});
                }
            });
        }]);

    app.run(['$rootScope', '$state', '$location', '$window',
        function ($rootScope, $state, $location, $window) {

            $rootScope.$on('$stateChangeSuccess', function () {
                $rootScope.isLoading = false;
            });

            $rootScope.$on('$viewContentLoaded', function () {
                if (window.isMobile) {
                    //var wrapper = document.getElementById('wrapper');
                    TweenMax.to(document.body, 0.8, {scrollTo:{y:0}, ease:Power2.easeOut});
                }
            });

            // ------------------------------------------
            // redirect to mobile
            // ------------------------------------------


            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
                $rootScope.isLoading = true;

                if (window.isMobile && window.os == 'iOS' && window.iOSVersion[0] < 10 && toState.name !== 'mobile') {
                    event.preventDefault();
                    $state.go('mobile');
                }
            });

            $rootScope.$on('$stateChangeError', function (event, toState) {
                console.log('$stateChangeError to ' + toState.name);
            });
        }]);
}());
/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name homeCarrousel
     *
     * @description clickable carrousel for the home
     *
     */
    angular.module('cyfi').directive("homeCarrousel", ['carrouselResource','$state','$interval','CARROUSEL_INTERVAL', function (carrouselResource,$state,$interval,CARROUSEL_INTERVAL) {
        return {
            restrict: 'E',
            templateUrl : 'app/view/home-carrousel-view.html',
            link: function(scope, element) {

                var selected        = 0,
                    carrousel       = element.children();

                scope.items  = carrouselResource.getItems();

                scope.goToVideo = function (videoId) {
                    $state.go('app.video', {id:videoId});
                };

                if(scope.items.length > 1) {
                    var carrouselTimer = $interval(function () {
                          nextSlide();
                      },
                      CARROUSEL_INTERVAL,
                      0,
                      false
                    );
                }

                function nextSlide(){

                    var items           = carrousel.children(),
                        carrouselWidth  = element[0].clientWidth,
                        currentSlide    = items[selected],
                        next            = selected + 1,
                        nextSlide;

                    TweenMax.to(currentSlide, 1,
                        {
                            x: -carrouselWidth,
                            force3D: true,
                            onComplete:function() {
                                TweenMax.set(this.target, {x: 0, opacity: 0, pointerEvents: 'none'});
                            }
                        }
                    );

                    if(items.length < (next + 1)){
                        selected = 0;
                        next     = 0;
                    }else{
                        selected++;
                    }
                    nextSlide    = items[next];

                    angular.element(nextSlide).css({
                        "opacity": 1,
                        "pointerEvents": "auto"
                    });

                    TweenMax.set(nextSlide, {x: carrouselWidth});
                    TweenMax.to(nextSlide, 1, {
                        x: 0,
                        force3D: true
                    });

                }
            }
        };
    }]);
}());

/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';
    var mod = angular.module('translate', []);

    /**
     * @ngdoc provider
     * @name language
     *
     * @description
     * The language service returns a locale string based on a key value
     */
    mod.provider('language', [function () {
        var translation = {};

        /**
         * Initialize the language provide with a translation object
         * @param {object} translationKeyValue key value pair {translationKey : 'translation text'}
         */
        this.setTranslation = function (translationKeyValue) {
            translation = translationKeyValue;
        };

        function Language($interpolate) {

            /**
             * @ngdoc method
             * @name language#getText
             * @kind function
             *
             * @param {string} key A translation key to be translated.
             * @param {*=} interpolateParams Optional object literal (as hash or string) to pass values into translation.
             *
             * @description
             * call like: language.getText('key.name', {username: "iets", score: 3});
             *
             * @returns {string} Translated text.
             */
            this.getText = function (key, interpolateParams) {
                var result = translation[key];
                if (result !== undefined) {
                    if (interpolateParams !== undefined && interpolateParams !== null) {

                        // https://docs.angularjs.org/api/ng/service/$interpolate
                        result = $interpolate(result)(interpolateParams);
                    }
                }
                return result || "no translation for: " + key;
            };

            this.hasText = function (key) {
                return translation[key] ? true : false;
            };
        }

        this.$get = ['$interpolate', function ($interpolate) {
            return new Language($interpolate);
        }];
    }]);


    /**
     * @ngdoc filter
     * @name translate
     *
     * @description
     *
     * @param {string} translationKey A translation key to be translated.
     * @param {*=} interpolateParams Optional object literal (as hash or string) to pass values into translation.
     *
     * @returns {string} Translated text.
     *
     * @example
     <example>
        <div ng-controller="TranslateCtrl">

        <pre>{{ 'TRANSLATION_ID' | translate }}</pre>
        <pre>{{ translationId | translate }}</pre>
        <pre>{{ 'WITH_VALUES' | translate:'{value: 5}' }}</pre>
        <pre>{{ 'WITH_VALUES' | translate:values }}</pre>

        </div>
     </file>

     </example>
     */
    mod.filter('translate', ['$parse', 'language', function ($parse, language) {
        return function (translationKey, interpolateParams) {
            //console.log("run filter", translationKey);
            return language.getText(translationKey, interpolateParams);
        };
    }]);

    /**
     * @ngdoc directive
     * @name translateHtml
     *
     * @description
     * Translate a string with html into the element
     *
     * @example
     * MY_KEY = <a href="http://google.nl">link</a>
     * <span translate-html="MY_KEY"></span>
     * outputs: <span><a href="http://google.nl">link</a></span>
     *
     * MY_KEY = <a href="{{link}}">link</a>
     * <span translate-html="MY_KEY" translateInterpolate="{link : 'http://google.nl'}"></span>
     * outputs: <span><a href="http://google.nl">link</a></span>
     *
     * MY_KEY = <a href="{{link}}">link</a>
     * <span translate-html="MY_KEY" translateInterpolateBind="{link : link}"></span>
     * outputs: <span><a href="http://google.nl">link</a></span> when scope.link = http://google.nl
     * And updates when scope value changes
     *
     */
    mod.directive('translateHtml', ['$compile', 'language', '$parse', '$interpolate', function ($compile, language, $parse, $interpolate) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attributes) {
                var interpolateParams, html;

                if ($attributes.translateInterpolateBind) {

                    $attributes.$observe('translateInterpolateBind', function (newvalue, oldvalue) {
                        var newHtml;
                        interpolateParams = $parse(newvalue)($scope);
                        newHtml = language.getText($attributes.translateHtml, interpolateParams)

                        if (html !== newHtml) {
                            html = newHtml;
                            $element.html(html);
                            $compile($element.contents())($scope);
                        }

                    } );

                } else {
                    if ($attributes.translateInterpolate) {
                        interpolateParams = $parse($attributes.translateInterpolate)($scope);
                    }
                    $element.html(language.getText($attributes.translateHtml, interpolateParams));
                    $compile($element.contents())($scope);

                }

            }
        };
    }]);
}()
);


/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('cyfi');

    /**
     * @ngdoc service
     * @name contentResource
     *
     * @description
     *  - list of all available content
     *  - list of context specific available content (filteredList)
     *
     * @return contentResource
     */
    module.service('contentResource', ['actorsResource', 'videosResource', 'shuffleArray', function (actorsResource, videosResource, shuffleArray) {
        var service = {};

        /**
         * Filter content items based on tags
         * @param {Array} tags
         */
        service.applyTagsFilter = function (tags) {
            if (tags === undefined || tags.length === 0) {
                service.filteredList = service.list.concat();
            } else {
                service.filteredList = [];

                for (var j = 0; j < service.list.length; j++) {
                    for (var i = 0; i < tags.length; i++) {
                        if (service.list[j].tags.indexOf(tags[i]) > -1) {
                            service.filteredList.push(service.list[j]);
                            break;
                        }
                    }
                }
            }
        };

        /**
         * Filter content items based on selected actor
         * @param actorId
         */
        service.applyActorFilter = function (actorId) {
            var item, i, j,otherActors;
            service.filteredList = [];

            otherActors = {};
            for (j = 0; j < service.list.length; j++) {
                item = service.list[j];
                // find all videos with actor
                if (item.type === 'video' && item.actors.indexOf(actorId) !== -1) {
                    service.filteredList.push(item);
                    for (i = 0; i < item.actors.length; i++) {
                        otherActors[item.actors[i]] = true;
                    }
                }
            }

            // add other actors
            for (j = 0; j < service.list.length; j++) {
                item = service.list[j];
                if (item.type === 'actor' && otherActors[item.id]) {
                    service.filteredList.push(item);
                }
            }
        };

        /**
         * Filter content items based on selected video
         * @param videoId
         */
        service.applyVideoFilter = function (videoId) {
            var item, j, k, video, actorlist, videolist;
            service.filteredList = [];


            for (j = 0; j < service.list.length; j++) {
                item = service.list[j];
                if (item.id === videoId) {
                    video = item;
                    break;
                }
            }

            for (j = 0; j < service.list.length; j++) {
                item = service.list[j];
                if (item !== video) {
                    // add actors in video
                    if (item.type === 'actor') {
                        if (video.actors.indexOf(item.id) !== -1) {
                            service.filteredList.push(item);
                        }
                    } else if (item.type === 'video' ) {
                        for (k = 0; k < item.tags.length; k++) {
                            if (video.tags.indexOf(item.tags[k]) !== -1) {
                                service.filteredList.push(item);
                                break;
                            }
                        }
                    }
                }
            }
        };

        // First add video-list and then actors-list
        service.list = shuffleArray(videosResource.list).concat(shuffleArray(actorsResource.list));

        /*
        // sort on name
        service.list.sort(
            function(a, b){
                if(a.name < b.name) return -1;
                if(a.name > b.name) return 1;
                return 0;
            }
        );
        */

        return service;
    }]);

    /**
     * @ngdoc service
     * @name tagsResource
     *
     * @description list of all available tags
     *
     * @return tagsResource
     */
    module.service('tagsResource', ['TAGS_DATA', function (tagsData) {
        var service = {};

        service.subjectTags = tagsData.concat().sort(firstBy(function(a) {
            return a.label; // Sort subjects on label
        }));

        return service;
    }]);

    /**
     * @ngdoc service
     * @name profileResource
     *
     * @description returns profileData by Id
     *
     * @return profileResource
     */
    module.service('profileResource', ['PROFILES_DATA', function (profileData) {
        var service = {};
        var _profileMap = {};// map id to actor object

        service.getById = function (profileId) {
            if (!_profileMap[profileId]) {
                for (var i = 0; i < profileData.length; i++) {
                    _profileMap[profileData[i].id] = profileData[i];
                }
            }

            if (!_profileMap[profileId]) {
                throw 'Unknow profile id: ' + profileId;
            }

            return _profileMap[profileId];

        };

        return service;
    }]);

    /**
     * @ngdoc service
     * @name actorsResource
     *
     * @description list of all actors
     *
     * @return actorsResource
     */
    module.service('actorsResource', ['actorFactory', 'ACTORS_DATA', function (actorFactory, actorsData) {

        var service = {};
        var _currentActor;
        var _actorMap = {};// map id to actor object

        service.list = [];

        for (var i= 0; i < actorsData.length; i++) {
            service.list.push(actorFactory.createNewInstance(actorsData[i]));
        }

        service.setCurrentActor = function (actorId) {
            _currentActor = null;
            for (var i = 0; i < service.list.length; i++) {
                if (service.list[i].id === actorId) {
                    _currentActor = service.list[i];
                    break;
                }
            }
        };

        service.getCurrentActor = function () {
            return _currentActor;
        };

        service.getById = function (actorId) {
            if (!_actorMap[actorId]) {
                for (var i = 0; i < service.list.length; i++) {
                    _actorMap[service.list[i].id] = service.list[i];
                }
            }

            if (!_actorMap[actorId]) {
                throw 'Unknow actor id: ' + actorId;
            }

            return _actorMap[actorId];
        };

        /**
         * return a list with actors matching the provided ids
         * @param ids {Array}
         */
        service.getActorsByIds = function (ids) {
            var result = [];
            for (var i = 0; i < ids.length; i++) {
                result.push(service.getById(ids[i]));
            }
            return result;
        }


        service.getParamById = function (param,actorId) {
            var actor = service.getById(actorId);
            if (!actor[param]) {
                throw 'Unknow actor param: ' + param + ' for actor ' + actorId;
            }
            return actor[param];

        };

        return service;
    }]);

    /**
     * @ngdoc service
     * @name videosResource
     *
     * @description list of all videos
     *
     * @return videosResource
     */
    module.service('videosResource', ['videoFactory', 'VIDEOS_DATA', function (videoFactory, videosData) {

        var service = {};
        var _currentVideo = null;

        service.list = [];

        for (var i = 0; i < videosData.length; i++) {
            service.list.push(videoFactory.createNewInstance(videosData[i]));
        }

        service.setCurrentVideo = function (videoId) {
            _currentVideo = null;
            for (var i = 0; i < service.list.length; i++) {
                if (service.list[i].id === videoId) {
                    _currentVideo = service.list[i];
                    break;
                }
            }
        };

        service.getCurrentVideo = function () {
            return _currentVideo;
        };

        return service;
    }]);


    /**
     * @ngdoc service
     * @name scenariosResource
     *
     * @description list of all scenarios
     *
     * @return scenariosResource
     */
    module.service('scenariosResource', ['scenarioFactory', 'SCENARIOS_DATA', function (scenarioFactory, scenariosData) {

        var service = {};
        var _currentScenario = null;

        service.list = [];

        (function parserData () {
            var index = 0;
            angular.forEach(scenariosData, function (value) {
               service.list.push(scenarioFactory.createNewInstance(value, 's' + index++));
            });
        }());

        service.setCurrentScenario = function (videoId, actorId) {
            _currentScenario = null;
            for (var i = 0; i < service.list.length; i++) {
                if (service.list[i].videoId === videoId && service.list[i].actorId === actorId) {
                    _currentScenario = service.list[i];
                    break;
                }
            }
        };

        service.getCurrentScenario = function () {
            return _currentScenario;
        };

        return service;
    }]);

    /**
     * @ngdoc service
     * @name carrouselResource
     *
     * @description list of carrousel-items
     *
     * @return carrouselResource
     */
    module.service('carrouselResource', ['HEADER_DATA', function (carrouselData) {

        var service = {},
            _carrouselData = carrouselData;

        service.getItems = function () {
            return _carrouselData;
        };

        return service;
    }]);
}());

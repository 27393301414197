/*jslint browser: true*/
/*global angular, console, window, ga */
(function () {
    'use strict';
    /**
     * @ngdoc service
     * @name Analytics
     *
     * @description Service to save Analytics data to Google Analytics
     *
     * @return Analytics
     */
    angular.module('Analytics-module', [])
        .provider('Analytics', function () {

            var trackingId;

            this.setTrackingId = function (id) {
                trackingId = id;
            };

            this.$get = function () {
                var service = {};

                /**
                 * Create and return Google Analytics object
                 */
                function _getGa() {

                    // create Google Analaytics object if not exist
                    /*
                    if (!window.ga) {
                        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

                        if (!trackingId) {
                            throw 'Please set the Google Analytics tracking id before tracking a page.';
                        }

                        ga('create', trackingId, 'auto');

                    }
                    */

                    return window.ga;

                }

                /**
                 * Translate view class name to Google Analytics name
                 *
                 * @param viewStateName
                 * @returns {String} Google analytics tracking name or null if view class name is unknown
                 */
                service.getPageName = function (viewStateName) {
                    var result;
                    switch (viewStateName) {
                        default :
                            console.info('Unknow Google Analytics screen name ' + viewStateName);
                            result = null;
                    }

                    return result;
                };

                /**
                 * Set global params for tracking
                 *
                 * @param paramName
                 * - appName
                 *
                 * @param value
                 */
                service.setTrackingParam = function (paramName, value) {
                    /*
                    var ga = _getGa();

                    switch (paramName) {
                        case 'appVersion':
                            ga('set', paramName, value);
                            break;
                        default:
                            console.error('Unknown Google Analytics param: ' + paramName);
                    }
                    */
                };

                service.trackPageView = function (pageName) {

                    if (pageName) {

                        window.ga && window.ga('send', 'pageview', { page: '/' + pageName});
                        /*
                        var ga = _getGa();
                        ga('set', 'page', '/' + pageName);
                        ga('send', 'pageview');
                        */
                        /*
                        ga('send', 'pageview', {
                            'page': pageName
                        });
                        */
                    }
                };


                return service;
            };

        });
}()
);

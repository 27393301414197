/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name videoHover
     *
     */
    angular.module('cyfi').directive('contentToggle', ['videoToggleService', function (videoToggleService) {
        return {
            restrict: 'E',
            remove: true,
            templateUrl: 'app/view/content-toggle.html',
            link: function($scope,$element,$attr) {

                $scope.toggleService = videoToggleService;

            }
        };
    }]);
}());

/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('cyfi');

    /**
     * @ngdoc service
     * @name scoresFactory
     *
     * @description
     *  - list of earned score objects
     *
     * @return scoresFactory
     */
    module.service('scoresFactory', ['$cookies', function ($cookies) {

        var ScoreVO = function (videoId, actorId, score) {
            var _score, _roundedScore, _stars = 0;

            function updateScore (score) {
                if (isNaN(score)) {
                    _score = 0;
                    _roundedScore = 0;
                    _stars = 0;
                } else {
                    _score = score;
                    _roundedScore = Math.min(Math.ceil(_score), 100);
                    _stars = _score/33,333;
                }
            }

            updateScore(score);

            this.videoId = videoId;
            this.actorId = actorId;

            this.addScore = function (score) {
                this.setScore(_score + score);
            };

            this.setScore = function (score) {
                updateScore (score);
            };

            Object.defineProperty(this, 'score', {
                get: function() {
                    return _roundedScore;
                }
            });

            Object.defineProperty(this, 'stars', {
                get: function() {
                    return _stars;
                }
            });

        };


        var ScoreObject = function (id, saveInCookie) {
            var _id = id;
            var _saveInCookie = saveInCookie;
            var _scoresMap = {}; //map [videoId + | + actorId] => score object

            /**
             * Determines if a profile has a score and therefor is not free to select
             * @return Boolean
             */
            this.isFree = function () {
                if(Object.keys(_scoresMap).length !== 0){
                    return false;
                }else{
                    return true;
                }
            };

            /**
             * Resets this score object
             */
            this.reset = function() {
                _scoresMap = {};
                this.save();
            };

            /**
             * Creates a temporarily score object for a scenario
             * use the updateScores function to save the score object in the sevice
             * @param videoId
             * @param actorId
             * @return {ScoreVO}
             */
            this.createNewScoreObject = function (videoId, actorId, score) {
                return new ScoreVO(videoId, actorId, score);
            };

            /**
             * Save the score in the service or override the current score object (same video and actor id) with the provided score object
             * @param scoreObject {ScoreVO}
             * @see createNewScoreObject
             */
            this.updateScores = function (scoreObject) {
                var uniqueId =  scoreObject.videoId + '|' + scoreObject.actorId;
                var existingScoreObject = _scoresMap[uniqueId];

                if (!existingScoreObject) {
                    _scoresMap[uniqueId] = scoreObject;
                } else {
                    if (existingScoreObject.score < scoreObject.score) {
                        existingScoreObject.setScore(scoreObject.score);
                    }
                }

                this.save();
            };

            this.getScoreObject = function (actorId, videoId) {
                var uniqueId =  videoId + '|' + actorId;
                if (!_scoresMap[uniqueId]) {
                    _scoresMap[uniqueId] = this.createNewScoreObject(actorId, videoId);
                }

                return _scoresMap[uniqueId];
            };


            this.save = function () {
                if (_saveInCookie) {
                    var store = {};
                    angular.forEach(_scoresMap, function (value, key) {
                        store[key] = value.score;
                    });

                    $cookies.putObject(_id, store);
                }
            };

            if (_saveInCookie) {
                (function load (me) {
                    var obj = $cookies.getObject(_id);

                    angular.forEach(obj, function (value, key) {
                        var ids = key.split('|');
                        _scoresMap[key] = me.createNewScoreObject(ids[1], ids[0], value);
                    });

                }(this));
            }
        };


        return {
            createNewInstance : function (id, saveInCookie) {
                return new ScoreObject(id, saveInCookie);
            }
        };
    }]);
}()
);

/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('array-utils', []);

    /**
     * Randomize array element order in-place.
     * Using Durstenfeld shuffle algorithm.
     */
    module.service('shuffleArray', function () {
        return function shuffleArray(array) {
            for (var i = array.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        };
    });


}());
/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name feedbackStar
     *
     */
    angular.module('cyfi').directive('feedbackStar', [function () {
        return {
            restrict: 'E',
            template: '<div class="star">' +
                        '<span class="star-score"></span>' +
                    '</div>',
            scope: {
                score: '='
            },
            link : function ($scope, $element) {
                var scoreEl = $element[0].querySelector('.star-score');
                var scoreTweenObject = {value : 0};
                updateScoreInElement(0);

                function updateScoreInElement (value) {
                    scoreEl.textContent = '+' + Math.round(value);
                }

                $scope.$watch('score', function (score) {
                    if (score) {
                        TweenMax.to(scoreTweenObject, 1.2, {delay: 0.4, value:score,
                            onUpdate:function updateHandler() {
                                updateScoreInElement(scoreTweenObject.value);
                            }});
                    } else {
                        updateScoreInElement(0);
                    }
                });

            }
        };

    }]);
}());
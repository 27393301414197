/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name linkSense
     *
     */
    angular.module('cyfi').directive('linkSense', [function () {
        return {
            restrict: 'E',
            template: "<div class='link-sense small__hidden'>" +
                        "<div class='link-sense-container'>" +
                            "<div class='link-sense-tab' ng-click='openCloseLinkSenseElement()'><span class='label'>{{'SENSE_LINK_TAB_LABEL' | translate }}</span></div>" +
                            "<div class='link-sense-content' ng-click='openLink()'>" +
                                "<h2>{{'SENSE_LINK_HEADER' | translate }}</h2>" +
                                "<p>{{'SENSE_LINK_CONTENT' | translate }}</p>" +
                            "</div></div></div>",
            link : function ($scope, $element) {
                $scope.openCloseLinkSenseElement = function () {
                    if ($element.hasClass('open')) {
                        $element.removeClass('open');
                    } else {
                        $element.addClass('open');
                    }
                };

                $scope.openLink = function () {
                    window.open('https://www.sense.info/hulp-advies', '_blank');
                }
            }
        };

    }]);
}());